import React, { useCallback, useEffect, useState } from "react";
import styles from "../Register/termcondition.module.css";
import { publicRequest, userRequest } from "../../requestMethod";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import BannerStyle from "./banner.module.css";

const TermsCondition = ({ setLocation, data }) => {
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [buttonDisable, setButtonDisabled] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure(); // Initialize useDisclosure
  const [legalRepresentativeData, setLegalRepresentativeData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const legalRepresentativeValue = JSON.parse(
        localStorage.getItem("legalRepresentativeData")
      );
      console.log("legalRepresentativeValue", legalRepresentativeValue);
      setLegalRepresentativeData(legalRepresentativeValue);
    } catch (error) { }
  }, []);

  const handleBack = () => {
    setLocation("form3");
  };

  const handleSubmit = async () => {
    if (!isChecked1 || !isChecked2 || !isChecked3) {
      onOpen();

      return;
    }
    if (isChecked1 && isChecked2 && isChecked3) {
      try {
        data.legalDetails = legalRepresentativeData;
        const sessionId = localStorage.getItem("sessionId");
        data.sessionId = sessionId;
        const userData = localStorage.getItem("userData");
        data.businessDetails = JSON.parse(userData)?.userData;

        console.log("data", data);
        const response1 = await userRequest.post("users", data);
        console.log(" ----- response1 ----- ", response1);
        message.success("Registered successfully");
        setLocation("form5");
        setButtonDisabled(true);

        localStorage.clear("formValue");
        localStorage.clear("sessionId");
        localStorage.clear("userData");
        navigate("/register?step=4");
      } catch (err) {
        setButtonDisabled(false);
        const errorMessage = err.response?.data?.message || "An error occurred";
        message.error(errorMessage);
      }
    }
  };

  const handleChange1 = () => {
    setIsChecked1(!isChecked1);
  };
  const handleChange2 = () => {
    setIsChecked2(!isChecked2);
  };
  const handleChange3 = () => {
    setIsChecked3(!isChecked3);
  };

  return (
    <div className={styles.container}>
      <div className={BannerStyle.banner_container}>

        <div className={BannerStyle.banner}>
          <div className={BannerStyle.bannerTitle}>ONE STEP AWAY!</div>
        </div>

        <div className={styles.paging_container}>
          <p className={styles.count}>4 / 4</p>
        </div>
      </div>
      <br></br>
      <div className={styles.grid}>
        <div className={styles.form2_main}>
          <div className={styles.conditionContainer}>
            <p className={styles.accept}>
              Please accept the following to complete your request:
            </p>
            <div className={styles.checkbox}>
              <label className={styles.f3_label}>
                <input
                  type="checkbox"
                  className={styles.f3_input}
                  checked={isChecked1}
                  onChange={handleChange1}
                />
                <span className={styles.span}>Terms & Conditions</span>
              </label>
              <label className={styles.f3_label}>
                <input
                  type="checkbox"
                  className={styles.f3_input}
                  checked={isChecked2}
                  onChange={handleChange2}
                />
                <span className={styles.span}>Partner Agreement</span>
              </label>
              <label className={styles.f3_label}>
                <input
                  type="checkbox"
                  className={styles.f3_input}
                  checked={isChecked3}
                  onChange={handleChange3}
                />
                <span className={styles.span}>Voucher Terms & Conditions</span>
              </label>
            </div>
          </div>
          {/* <div className={styles.conditionContainer}>
            <p className={styles.accept}>
              Please accept the following to complete your request:
            </p>
            <div className={styles.checkbox}>
              <label className={styles.f3_label}>
                <input
                  type="checkbox"
                  className={styles.f3_input}
                  checked={isChecked1}
                  onChange={handleChange1}
                />
                <span className={styles.span}>Terms & Conditions</span>
              </label>
              <label className={styles.f3_label}>
                <input
                  type="checkbox"
                  className={styles.f3_input}
                  checked={isChecked2}
                  onChange={handleChange2}
                />
                <span className={styles.span}>Partner Agreement</span>
              </label>
              <label className={styles.f3_label}>
                <input
                  type="checkbox"
                  className={styles.f3_input}
                  checked={isChecked3}
                  onChange={handleChange3}
                />
                <span className={styles.span}>Voucher Terms & Conditions</span>
              </label>
            </div>
          </div> */}
          {/* <div>
            <p className={styles.heading}>Your selected plan:</p>
          </div>
          <div className={styles.card_box}>
            <div className={styles.contentBox}>
              <div>
                <p>
                  <b>Plan : {selected?.planName}</b>
                </p>
                <p>
                  <b>Price : </b>
                  <b>{discount > 0 ? discount.toFixed(2) : null}</b>
                  {discountMonths > 0 && discountMonths !== 2 ? (
                    `${discountMonths} month${
                      discountMonths > 1 ? "s" : ""
                    } free`
                  ) : (
                    <span
                      style={{
                        textDecoration: discount > 0 ? "line-through" : "",
                        marginLeft: 5,
                        fontSize: 16,
                      }}
                    >
                      €{selected?.price}
                      {discountMonths === 2 && " + 1 month free"}
                    </span>
                  )}
                </p>
              </div>
            </div>
            <div className={styles.cardFooter}>
              <div className={styles.voucher}>
                <p>Got a voucher?</p>
              </div>
              <div className={styles.enter_voucher}>
                <input
                  disabled={discount > 0 || discountMonths > 0}
                  type="text"
                  placeholder="Enter your voucher here"
                  value={voucher}
                  onChange={(e) => {
                    setVoucher(e.target.value);
                  }}
                />

                {voucher && (
                  <button
                    className={styles.remove_voucher}
                    onClick={clearVoucher}
                    title="Remove Voucher"
                  >
                    &times;
                  </button>
                )}
              </div>
            </div>
          </div> */}
        </div>

        {/* 
        <div className={styles.card_box}>
          <div className={styles.contentBox}>
            <div>
              <p>
                <b>Plan : {selected?.planName}</b>
              </p>
              <p>
                <b>Price : </b>
                <b>{discount > 0 ? discount.toFixed(2) : null}</b>
                {discountMonths > 0 && discountMonths !== 2 ? (
                  `${discountMonths} month${discountMonths > 1 ? "s" : ""} free`
                ) : (
                  <span
                    style={{
                      textDecoration: discount > 0 ? "line-through" : "",
                      marginLeft: 5,
                      fontSize: 16,
                    }}
                  >
                    €{selected?.price}
                    {discountMonths === 2 && " + 1 month free"}
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className={styles.cardFooter}>
            <div className={styles.voucher}>
              <p>Got a voucher?</p>
            </div>
            <div className={styles.enter_voucher}>
              <input
                disabled={discount > 0 || discountMonths > 0}
                type="text"
                placeholder="Enter your voucher here"
                value={voucher}
                onChange={(e) => {
                  setVoucher(e.target.value);
                }}
              />

              {voucher && (
                <button
                  className={styles.remove_voucher}
                  onClick={clearVoucher}
                  title="Remove Voucher"
                >
                  &times; 
                </button>
              )}
            </div>
          </div>
        </div> */}
      </div>
      <div className={styles.acc_nsubmit}>
        <button onClick={handleBack}>BACK</button>
        <button onClick={() => handleSubmit()} disabled={buttonDisable}>
          FINISH
        </button>
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          width="100%"
          borderRadius="18px"
          className={styles.modelContent}
        >
          <ModalHeader style={{ color: "#df5468", textAlign: "center" }}>
            Important!
          </ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            <p>Please accept the following to</p>
            <p> complete your request</p>
          </ModalBody>
          <ModalFooter>
            <button
              style={{
                margin: "auto",
                backgroundColor: "#333333",
                color: "#FFFFFF",
                fontFamily: "GalanoGrotesque-Black",
                width: "50%",
                padding: "4%",
                borderRadius: "8px",
              }}
              onClick={onClose}
            >
              Got It
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default TermsCondition;
