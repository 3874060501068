import React, { useEffect, useRef, useState } from "react";
import { AutoComplete, Input, Spin } from "antd";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useLoadScript } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
const libraries = ["places", "geometry"];

const LocationPicker = ({
  setUserAddress,
  setLatitude,
  setLongitude,
  userAddress,
  error,
  setError,
  addressError,
  setAddressError,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY, // Replace with your Google Maps API key
    libraries,
  });

  if (!isLoaded) return <h1>loading..</h1>;

  return (
    <PlacesAutocomplete
      error={error}
      setError={setError}
      addressError={addressError}
      setAddressError={setAddressError}
      setLatitude={setLatitude}
      setLongitude={setLongitude}
      userAddress={userAddress}
      setUserAddress={setUserAddress}
    />
  );
};

const PlacesAutocomplete = ({
  setUserAddress,
  setLatitude,
  setLongitude,
  userAddress,
  error,
  setError,
  addressError,
  setAddressError,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    initialValue: userAddress?.address || "",
  });
  const { t } = useTranslation();


  useEffect(() => {
    if (userAddress && userAddress.address) {
      if (!/\d+/.test(userAddress.address)) {
        setAddressError("");
        setError("Add your house number");
        setUserAddress(null); // Clear the address
        setLatitude(null); // Clear latitude
        setLongitude(null); // Clear longitude
        return;
      } else {
        setError(null);
        setValue(userAddress.address, false);
        setAddressError("");
      }
    }
  }, [userAddress]);

  useEffect(() => {
    if (value) {
      if (/\d+/.test(value)) {
        setError("Choose your address from the dropdown");
        return;
      }
    }
    if (!value) {
      setError(null);
    }
  }, [value]);

  const [options, setOptions] = useState(data);
  const [isOpenOption, setIsOpenOption] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    setIsOpenOption(false)
    

    try {
      const results = await getGeocode({ address });
      const addressComponents = results[0].address_components;

      const city = addressComponents.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      const country = addressComponents.find((component) =>
        component.types.includes("country")
      )?.long_name;

      const { lat, lng } = await getLatLng(results[0]);
      setUserAddress({
        city,
        country,
        address,
        lng,
        lat,
      });
      setLatitude(lat);
      setLongitude(lng);
    } catch (error) {
      console.log("Error: ", error);
    }
  };


  const customStyles = {
    container: {
      width: "100%", // Adjust the width as needed
      marginLeft: "0px",
    },
    input: {
      // Custom input styles here
      width: "100%",
      height: 55,
      background: "transparent",
      border: "none",
    },
    suggestionsList: {
      backgroundColor: "white",
      marginTop: "4px",
      position: "absolute",
      zIndex: 9999,
    },
    suggestion: {
      cursor: "pointer",
      padding: "10px",
    },
    control: {
      width: "100%",
      minHeight: "10px",
      height: "55px",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      fontFamily: "GalanoGrotesque-Medium",
      fontSize: "14px", // Set a default font size
      backgroundColor: "#f7fcff",
      fontWeight: "500",
      backgroundImage: "url(../../../public/Images/dropdown.svg)",
    },
  };


  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenOption(false); 
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);


  useEffect(() => {
    setOptions(data);
    setIsOpenOption(true)

  }, [data]);


  


  return (
    <div style={customStyles.container}>
      <div style={{
        position: "relative",
      }}>
        <input
          type="text"
          style={{ ...customStyles.control, padding: "0px 22px" }}
          value={value}
          onChange={handleChange}
          placeholder={t("register.searchAddress")}
          onClick={()=>setIsOpenOption(true)}
        />
        {options && isOpenOption && (
          <div
          ref={dropdownRef}
            style={{
              position: "absolute",
              top: "56px",
              left: 0,
              background: "#fff",
              width: "100%",
              zIndex: 1000,
              display: "hidden",
              padding: "0px 8px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", 
            }}
          >
            {options.map((item, index) => (
              <div
                key={index}
                style={{
                  padding: "4px 8px",
                  cursor: "pointer",
                  whiteSpace: "nowrap", 
                  overflow: "hidden",  
                  textOverflow: "ellipsis", 
                  fontSize: "14px"
                }}
                onClick={() => handleSelect(item.description)}
              >
                {item.description}
              </div>
            ))}
          </div>
        )}
      </div>


     
      {error && (
        <div style={{ color: "red", marginTop: "8px", marginLeft: "1rem" }}>
          {error}
        </div>
      )}
      {addressError && (
        <div style={{ color: "red", marginTop: "8px", marginLeft: "1rem" }}>
          {addressError}
        </div>
      )}
    </div>
  );
};

export default LocationPicker;
