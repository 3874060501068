import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer/Footer";
import Routepage from "./Routepage";
import Header from "./Header/Header";
import styles from "./navpages.module.css";
import { publicRequest, sendEmail } from "../requestMethod";
import { message } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
function Navpages() {
  const { t } = useTranslation();
  const [popUp1, setPopUp1] = useState(false);
  const [banner1, setBanner1] = useState(false);
  const [banner2, setBanner2] = useState(false);
  const recaptcha = useRef(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: process.env.REACT_APP_GUEST_LIST_EMAIL_SUBJECT,
    sendEmail: process.env.REACT_APP_GUEST_LIST_EMAIL,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  

  const handleSubmit = async () => {
    if (!formData.email) return alert("Enter email to continue");
    try {
      const captchaValue = recaptcha.current.getValue();
      if (!captchaValue) {
        alert("Please verify the reCAPTCHA!");
      } else {
        const res = await publicRequest.post("/verify", { captchaValue });
        if (res?.data?.success) {
          // make form submission
          await sendEmail
            .post("/users/sendGuestListEmail", JSON.stringify(formData))
            .then(() => {
              message.success(
                "Success! You're officially on the guestlist! Keep an eye on your email for all the exciting details coming your way soon.."
              );
              setBanner1(false);
              setBanner2(false);
            })
            .catch((err) => {
              const errorMessage =
                err.response?.data?.message || "An error occurred";
              message.error(errorMessage);
            });
          // alert("Form submission successful!");
        } else {
          alert("reCAPTCHA validation failed!");
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };
  const handlePopUp = () => {
    setPopUp1((prev) => !prev);
  };
  const handleBanner1 = () => {
    setBanner1((prev) => !prev);
  };

  const handleBanner2 = () => {
    handleBanner1();
    setBanner2((prev) => !prev);
  };
  const handleBanner2State = () => {
    setBanner2((prev) => !prev);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handlePopUp();
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const url = window.location.href; 
    if (!url.includes("register")) {
      handleBanner1();
    }
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div className={styles.container}>
      <Header />
      <Routepage />
      <Footer />

      {banner1 && (
        <div className={styles.popUp1Container}>
          <div className={styles.bannerBox}>
            <p className={styles.closeBanner1} onClick={handleBanner1}>
              X
            </p>
            <img
              className={styles.bannerImg}
              src="Images/banner1.png"
              alt="none"
              onClick={handleBanner2State}
            />
          </div>
        </div>
      )}

      {banner2 && (
        <div className={styles.popUp1Container}>
          <div className={styles.banner2}>
            <p className={styles.close} onClick={handleBanner2}>
              {t("banner1.close")}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <p className={styles.banner2Text} style={{ fontSize: "1.5rem" }}>
                {t("banner1.banner2Text")}
              </p>
              <p className={styles.banner2Text}>
                {t("banner1.banner2SubText")}
              </p>
              <div className={styles.banner2inputBox}>
                <input
                  className={styles.banner2input}
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder={t("banner1.firstNamePlaceholder")}
                />
                <input
                  className={styles.banner2input}
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder={t("banner1.lastNamePlaceholder")}
                />
              </div>
              <input
                className={styles.banner2Input}
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder={t("banner1.emailPlaceholder")}
              />
              <div
                className={styles.recaptcha_btn}
                style={{ transform: "scale(0.85)", transformOrigin: "0 0" }}
              >
                <ReCAPTCHA
                  ref={recaptcha}
                  sitekey={process.env.REACT_APP_SITE_KEY}
                />
              </div>
              <ul className={styles.list}>
                {t("banner1.list", { returnObjects: true }).map(
                  (item, index) => (
                    <li key={index}>{item}</li>
                  )
                )}
              </ul>
              <button onClick={handleSubmit} className={styles.banner2btn}>
                {t("banner1.getOnTheListButton")}
              </button>
            </div>
            <p className={styles.banner2FooterText}>
              {t("banner1.footerText")}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navpages;
